import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFeedbackFromResponseID } from '../../clients/rotr-client';
import { Box, Paper, Button, IconButton } from '@mui/material';
import TargetsTable from './report-details-table';
import TableContainer from '@mui/material/TableContainer';
import StatementsTable from './statements-table';
import Actions from './actions';
import ResultantsTable from './resultants-table';
import ContactMonsTable from './contact-monitoring-table';
import ErrorDialog from '../common/ErrorDialog';
import CircularProgress from '@mui/material/CircularProgress';
import PaperCanvas from './paper-canvas';
import { validateData } from './validateFeedbackData';
import { drawShip, buildShips, buildTSS, drawRR, drawTSS } from './shipModule';
import { Grid, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import DateIcon from '@mui/icons-material/DateRange';
import TimeIcon from '@mui/icons-material/AccessTime';
import InfoIcon from '@mui/icons-material/Info';
import AppFeedbackList from './application-feedback';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import Tooltip from '@mui/material/Tooltip';
import cloneDeep from 'lodash/cloneDeep';
import TaskCompleteBanner from '../tasks/TaskCompleteBanner';

const CustomBox = (props: any) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
      // bgcolor={"yellow"}
      {...props} // Spread other provided props
    />
  );
};

interface FeedbackData {
  organizedDetails: any;
  reportRules: any;
  reportStatements: any;
  scenarioResponse: any;
  rulesApplicationFeedback: any;
  explanation: any;
  taskComplete?: boolean | null;
  taskTitle?: string | null;
  taskType?: string | null;
  nextTask?: {
    task_url?: string | null;
    task_type?: 'scenario' | 'recite' | 'game' | null;
    task_title?: string | null;
  };
}
type MessageData = {
  context?: string; // Define the type of 'context' if known, or leave it as 'any' for now
  [key: string]: any; // This allows for other properties on the event.data object
};

interface FeedbackProps {
  inputId?: string;
}
const Feedback = ({ inputId }: FeedbackProps) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState<MessageData | null>(null);
  const [errorDialogTitle, setErrorDialogTitle] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState('');
  const [data, setData] = useState<FeedbackData | null>(null);
  const [dataForScenarioViewer, setSVData] = useState<FeedbackData | null>(
    null
  );
  const { id } = useParams<{ id: string }>();
  const [feedbackId, setFeedbackId] = useState<string | undefined>(
    inputId || id
  );
  const [iframeClassname, setIframeClassname] = useState('iframe-hidden-modal');
  const [iframeKey, setIframeKey] = useState(0); //used to force iframe reload
  const [feedbackDisplay, setFeedbackDisplay] = useState('');
  const [viewerReady, setViewerReady] = useState(false);
  const [scenarioDataSent, setScenarioDataSent] = useState(false);
  const [showScenarioViewCloseButton, setShowScenarioViewCloseButton] =
    useState(false);
  const [isDrawn, setIsDrawn] = useState(false);
  const [showFullExplanation, setShowFullExplanation] = useState(false);
  const [isParentReadySent, setIsParentReadySent] = useState(false);

  // Function to toggle the explanation view
  const toggleExplanationView = () => {
    setShowFullExplanation(!showFullExplanation);
  };
  const handleSetOpenErrorDialog = (open: boolean) => {
    setOpenErrorDialog(open);
  };
  const drawSituation = (scope: paper.PaperScope) => {
    const updatedShips = buildShips(data?.scenarioResponse.final_ships_afloat);
    if (data?.scenarioResponse.final_tss_details) {
      const updatedTSS = buildTSS(
        data?.scenarioResponse.final_ships_afloat[0].position,
        data?.scenarioResponse.final_tss_details,
        data?.scenarioResponse.final_onemile_value
      );
      drawTSS(updatedTSS);
    }
    drawRR(120, 120);
    updatedShips.forEach((ship, i) => {
      if (i === 0) {
        drawShip(ship, ship); // Assuming the first ship is the own ship
      } else {
        drawShip(ship, updatedShips[0]);
      }
    });
  };

  //Scenario Viewer Functions
  useEffect(() => {
    const handleMessage = (event: MessageEvent<MessageData>) => {
      if (event.origin !== targetOrigin) return;

      const data = event.data;
      if (
        typeof data == 'string' &&
        data === 'viewer-ready' &&
        !isParentReadySent
      ) {
        console.log('set parent ready true');
        setIsParentReadySent(true);
        iframeRef.current?.contentWindow?.postMessage(
          'parent-ready',
          targetOrigin
        );
        if (typeof data === 'object' && data !== null && 'context' in data) {
          // Handle other messages
          setMessage(data);
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [isParentReadySent]);

  const showScenarioViewer = (show: boolean) => {
    if (show) {
      setIframeClassname('iframe-fullscreen');
      setFeedbackDisplay('none');
      setShowScenarioViewCloseButton(true);
    } else {
      setIframeClassname('iframe-hidden-modal');
      setFeedbackDisplay('');
      setShowScenarioViewCloseButton(false);
    }
  };
  // post a message to the iframe
  const iframeRef = useRef<HTMLIFrameElement>(null);
  let targetOrigin = process.env.REACT_APP_URL || 'localhost:3000';

  const StartScenario = async () => {
    setIsLoading(true);
    const scenarioDetails = {
      genShipsAfloat:
        dataForScenarioViewer?.scenarioResponse.final_ships_afloat,
      center: [
        dataForScenarioViewer?.scenarioResponse.final_ships_afloat[0].position
          .x,
        dataForScenarioViewer?.scenarioResponse.final_ships_afloat[0].position.y
      ],
      env: dataForScenarioViewer?.scenarioResponse.environment,
      experimentMode: true,
      TSS: dataForScenarioViewer?.scenarioResponse.final_tss_details
        ? dataForScenarioViewer?.scenarioResponse.final_tss_details
        : null,
      oneMile: dataForScenarioViewer?.scenarioResponse.final_onemile_value
        ? dataForScenarioViewer?.scenarioResponse.final_onemile_value
        : null
    };

    if (isParentReadySent) {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        console.log(
          Date.now(),
          'posting scenarioDetails data to scenario viewer...'
        );
        const clonedData = JSON.parse(JSON.stringify(scenarioDetails));
        iframe.contentWindow.postMessage(clonedData, targetOrigin);
      } else {
        console.log("didn't send message");
      }
      showScenarioViewer(true);
      setIsLoading(false);
      setScenarioDataSent(true);
    } else return;
  };
  useEffect(() => {
    if (feedbackId) {
      getFeedbackFromResponseID(feedbackId)
        .then((response) => {
          const {
            organizedDetails,
            reportRules,
            reportStatements,
            scenarioResponse,
            rulesApplicationFeedback,
            explanation,
            taskTitle,
            taskType,
            taskComplete,
            nextTask
          } = response;
          const dataToValidate: FeedbackData = {
            organizedDetails,
            reportRules,
            reportStatements,
            scenarioResponse,
            rulesApplicationFeedback,
            explanation,
            taskComplete,
            taskTitle,
            taskType,
            nextTask
          };
          setData(dataToValidate);
          const svData: FeedbackData = cloneDeep({
            organizedDetails,
            reportRules,
            reportStatements,
            scenarioResponse,
            rulesApplicationFeedback,
            explanation,
            taskTitle,
            taskType
          });
          setSVData(svData);
          // return validateData(dataToValidate).then((isValid) => {
          //   if (isValid) {
          //     setData(dataToValidate);
          //   }
          // });
        })
        .catch((err) => {
          setErrorDialogTitle('Error retrieving feedback');
          setErrorDialogMessage(err.response?.data?.error || err.message);
          setOpenErrorDialog(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [feedbackId]);

  const handleTryAgainClick = () => {
    navigate(
      `/practice/${data?.scenarioResponse.scenario_template_id}/${data?.scenarioResponse.traffic_level}/${data?.scenarioResponse.environment}`
    );
  };

  const handleExperimentClick = () => {
    if (scenarioDataSent) showScenarioViewer(true);
    else StartScenario();
  };

  return (
    <div className='modal-content'>
      <CustomBox>
        <h1>Scenario Feedback</h1>
      </CustomBox>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000
          }}
        >
          <CircularProgress />{' '}
        </div>
      ) : (
        <div>
          <Box display={feedbackDisplay}>
            <TaskCompleteBanner payload={(data as any) || {}} />
            <Box>
              {/* Grid container contains scenario details and rules application */}
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  {/* the Box contains the scenario details */}
                  <Box
                    border={0.5}
                    padding={2}
                    borderColor={'#888'}
                    borderRadius='4px'
                    // bgcolor={"blue"}
                    marginLeft={{ md: 1, lg: 3 }}
                    textAlign={{ sm: 'center', lg: 'left' }}
                    height='100%'
                  >
                    <Grid
                      container
                      spacing={{ xs: 0, lg: 3 }}
                      alignContent={'center'}
                      alignItems={'center'}
                    >
                      <Grid item xs={12} sm={6}>
                        {/* Box with content that is horizontally center aligned */}
                        <Box display='flex' justifyContent='center'>
                          <div
                            style={{
                              position: 'relative',
                              width: '240px',
                              height: '240px',
                              alignContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            {' '}
                            <i
                              className='fa-solid fa-up-right-and-down-left-from-center'
                              style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                cursor: 'pointer',
                                padding: '4px',
                                border: 'solid 0.25px #888',
                                borderRadius: '4px',
                                backgroundColor: '#161A1Dff',
                                fontSize: '15px'
                              }}
                              onClick={handleExperimentClick}
                            ></i>
                            <PaperCanvas
                              width={240}
                              height={240}
                              drawFunction={drawSituation}
                            />
                          </div>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        textAlign={{ xs: 'center', lg: 'left' }}
                        style={
                          {
                            // marginLeft: '0',
                            // paddingLeft: '24px',
                            // minWidth: '250px',
                          }
                        }
                      >
                        <Typography variant='h5' gutterBottom>
                          Scenario Details
                        </Typography>

                        <Box
                          display='flex'
                          flexDirection='column'
                          alignItems={{ xs: 'center', lg: 'flex-start' }}
                        >
                          {data?.scenarioResponse && (
                            <div>
                              <Typography
                                variant='body1'
                                display='flex'
                                alignItems='center'
                                gutterBottom
                              >
                                <DateIcon
                                  fontSize='small'
                                  style={{ marginRight: 8 }}
                                />
                                <strong>Date:&nbsp;</strong>{' '}
                                {new Date(
                                  data?.scenarioResponse.started_at
                                ).toLocaleDateString(undefined, {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                                  hour12: false
                                })}
                              </Typography>

                              <Typography
                                variant='body1'
                                display='flex'
                                alignItems='center'
                                gutterBottom
                              >
                                <TimeIcon
                                  fontSize='small'
                                  style={{ marginRight: 8 }}
                                />
                                <strong>Time:&nbsp;</strong>{' '}
                                {new Date(
                                  data?.scenarioResponse.started_at
                                ).toLocaleTimeString(undefined, {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  second: '2-digit',
                                  hour12: false
                                })}
                              </Typography>
                              <Divider style={{ margin: '8px 0', border: 0 }} />
                              <Typography
                                variant='body1'
                                display='flex'
                                alignItems='center'
                                gutterBottom
                              >
                                <InfoIcon
                                  fontSize='small'
                                  style={{ marginRight: 8 }}
                                />
                                <strong>Context:&nbsp;</strong>{' '}
                                {data.scenarioResponse.context}
                              </Typography>
                              <Typography
                                variant='body1'
                                display='flex'
                                alignItems='center'
                                gutterBottom
                              >
                                <InfoIcon
                                  fontSize='small'
                                  style={{ marginRight: 8 }}
                                />
                                <strong>Environment:&nbsp;</strong>{' '}
                                {data.scenarioResponse.environment}
                              </Typography>
                              <Divider style={{ margin: '8px 0', border: 0 }} />
                              <Tooltip
                                title='create a new scenario that looks like this one'
                                arrow
                              >
                                <Button
                                  variant='contained'
                                  onClick={handleTryAgainClick}
                                >
                                  Regenerate Scenario
                                </Button>
                              </Tooltip>
                              <Divider
                                style={{ margin: '16px 0', border: '0' }}
                              />
                            </div>
                          )}
                        </Box>
                        {data?.explanation && (
                          <>
                            <Typography
                              variant='h5'
                              gutterBottom
                              textAlign={{ sm: 'center', lg: 'left' }}
                            >
                              Explanation
                            </Typography>
                            <CustomBox>
                              <Typography variant='body1' gutterBottom>
                                {showFullExplanation
                                  ? data?.explanation
                                  : `${data?.explanation?.substring(
                                      0,
                                      100
                                    )}...`}
                                <a
                                  href='#'
                                  onClick={toggleExplanationView}
                                  style={{
                                    color: '#61dafb',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    marginLeft: '8px'
                                  }}
                                >
                                  {showFullExplanation
                                    ? 'See less'
                                    : 'See more'}
                                </a>
                              </Typography>
                            </CustomBox>{' '}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item sm={12} lg={6}>
                  <CustomBox>
                    <Typography
                      variant='h5'
                      gutterBottom
                      textAlign={{ sm: 'center', md: 'left' }}
                      style={{ marginLeft: '16px' }}
                    >
                      Rules Application
                    </Typography>
                    <Box
                      display='flex'
                      flexDirection='column'
                      flexWrap={'wrap'}
                      // alignItems={{ sm: "center", md: "flex-start" }}
                    >
                      <div>
                        <AppFeedbackList
                          feedbacks={data?.rulesApplicationFeedback}
                        />
                      </div>
                    </Box>
                  </CustomBox>
                </Grid>
              </Grid>
            </Box>
            <CustomBox padding={2}>
              <Typography variant='h5' gutterBottom>
                Contact Reports
              </Typography>
              <TableContainer
                component={Paper}
                sx={{ backgroundColor: '#161A1Dff' }}
              >
                {data && (
                  <TargetsTable
                    organizedDetails={data.organizedDetails}
                    reportRules={data.reportRules}
                  />
                )}{' '}
              </TableContainer>
            </CustomBox>
            <CustomBox padding={2}>
              <Typography variant='h5'>Actions</Typography>
              {/* <Button>try a different action?</Button> */}
              <Typography
                sx={{
                  color: '#61dafb',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
              >
                <a onClick={handleExperimentClick}>try a different action?</a>
              </Typography>

              <Actions
                action_course={data?.scenarioResponse.action_course}
                action_speed={data?.scenarioResponse.action_speed}
              ></Actions>
            </CustomBox>
            <CustomBox padding={2}>
              <Typography variant='h5' gutterBottom>
                Resultant Contact Details
              </Typography>
              <TableContainer
                component={Paper}
                sx={{ backgroundColor: '#161A1Dff' }}
              >
                {data && (
                  <ResultantsTable
                    final_ships_afloat={data.scenarioResponse.final_ships_afloat.slice(
                      1
                    )}
                    resultant_ships_afloat={data.scenarioResponse.resultant_ships_afloat?.slice(
                      1
                    )}
                  />
                )}{' '}
              </TableContainer>
            </CustomBox>
            <CustomBox padding={2}>
              <Typography variant='h5' gutterBottom>
                Agreements with CO
              </Typography>
              <TableContainer
                component={Paper}
                sx={{ backgroundColor: '#161A1Dff' }}
              >
                {data && <StatementsTable statements={data.reportStatements} />}{' '}
              </TableContainer>
            </CustomBox>
            <CustomBox padding={2}>
              <Typography variant='h5' gutterBottom>
                Contact Monitoring
              </Typography>
              <TableContainer
                component={Paper}
                sx={{ backgroundColor: '#161A1Dff' }}
              >
                {data && (
                  <ContactMonsTable
                    final_ships_afloat={data.scenarioResponse.final_ships_afloat.slice(
                      1
                    )}
                  />
                )}{' '}
              </TableContainer>
            </CustomBox>{' '}
          </Box>
          <iframe
            className={iframeClassname}
            ref={iframeRef}
            key={iframeKey}
            src='/scenario_viewer/index.html'
            title='scenario-viewer'
          />
          {showScenarioViewCloseButton ? (
            <Box
              sx={{
                position: 'absolute',
                padding: 0,
                top: { xs: 31, sm: -26 },
                left: -20
              }}
            >
              <IconButton
                size='large'
                sx={{ color: 'white', position: 'absolute', top: 15, left: 10 }}
                onClick={() => {
                  showScenarioViewer(false);
                }}
                hidden={true}
              >
                <CancelTwoToneIcon fontSize='large' />
              </IconButton>
            </Box>
          ) : null}
        </div>
      )}
      <ErrorDialog
        open={openErrorDialog}
        setOpen={handleSetOpenErrorDialog}
        title={errorDialogTitle}
        message={errorDialogMessage}
      />
    </div>
  );
};

export default Feedback;
