import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getFeedbackSummaryQuiz } from '../../clients/rotr-client';

interface QuizFeedbackSummaryProps {
  userID?: string;
  feedbacks?: any[];
}

const QuizFeedbackSummary: React.FC<QuizFeedbackSummaryProps> = ({
  userID,
  feedbacks: initialFeedbacks = []
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [dataRows, setDataRows] = useState<any[]>(initialFeedbacks);
  const [totalRows, setTotalRows] = useState(0);
  const [startDateFilter, setStartDateFilter] = useState<Date | null>(null);
  const [endDateFilter, setEndDateFilter] = useState<Date | null>(null);
  const [loadingData, setLoadingData] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const handleCloseError = () => setOpenError(false);

  const columns: GridColDef[] = [
    { field: 'created_at', headerName: 'Date & Time', width: 200 },
    { field: 'step_name', headerName: 'Quiz', width: 400 },
    {
      field: 'score',
      headerName: 'Score',
      width: 100,
      valueFormatter: (params) => `${params.value.toFixed(0)}%`
    },
    {
      field: 'time_taken_secs',
      headerName: 'Time Taken',
      width: 150,
      valueGetter: (params) => {
        if (params.value !== null) {
          return params.value;
        }
        const startTime = new Date(params.row.raw_created_at).getTime();
        const endTime = new Date(params.row.raw_completed_at).getTime();
        return Math.round((endTime - startTime) / 1000);
      },
      valueFormatter: (params) => {
        const totalSeconds = params.value;
        if (isNaN(totalSeconds)) {
          return 'N/A';
        }
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}m ${seconds}s`;
      }
    }
  ];

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) {
      console.log('Date string is undefined or empty');
      return 'N/A';
    }

    console.log('Raw date string:', dateString);

    let date = new Date(dateString);

    if (isNaN(date.getTime())) {
      date = new Date(dateString.replace(' ', 'T') + 'Z');
    }

    if (isNaN(date.getTime())) {
      console.log('Failed to parse date:', dateString);
      return dateString;
    }

    return date.toLocaleString();
  };

  const fetchQuizFeedbackSummary = async () => {
    if (initialFeedbacks.length > 0) {
      setDataRows(
        initialFeedbacks.map((row, index) => ({
          ...row,
          id: index,
          created_at: formatDate(row.created_at)
        }))
      );
      setTotalRows(initialFeedbacks.length);
      return;
    }

    setLoadingData(true);
    try {
      const response = await getFeedbackSummaryQuiz({
        pageSize,
        page: page + 1,
        started_at: startDateFilter?.toISOString(),
        ended_at: endDateFilter?.toISOString(),
        user_id: userID
      });

      const formattedRows = response.data.map((row: any, index: number) => ({
        ...row,
        id: index,
        raw_created_at: row.created_at,
        raw_completed_at: row.completed_at,
        created_at: formatDate(row.created_at),
        completed_at: formatDate(row.completed_at),
        time_taken_secs:
          row.time_taken_secs !== null ? row.time_taken_secs : null
      }));

      setDataRows(formattedRows);
      setTotalRows(response.total_hits);
    } catch (err: any) {
      setErrMsg(err.message);
      setOpenError(true);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchQuizFeedbackSummary();
  }, [pageSize, page, startDateFilter, endDateFilter, userID]);

  const datePickerStyle = {
    // ... (keep the existing datePickerStyle object)
  };

  return (
    <div className={userID ? 'modal-content' : 'main-content'}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingData}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Box padding={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '20px',
            flexWrap: 'wrap'
          }}
          gap={2}
        >
          Filters
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='Start date'
              slotProps={{
                actionBar: {
                  actions: ['clear']
                },
                layout: {
                  sx: {
                    backgroundColor: '#333'
                  }
                }
              }}
              sx={datePickerStyle}
              value={startDateFilter}
              format='DD/MM/YYYY'
              onChange={(newValue: any) => setStartDateFilter(newValue)}
            />
            -
            <DatePicker
              label='End date'
              slotProps={{
                actionBar: {
                  actions: ['clear']
                },
                layout: {
                  sx: {
                    backgroundColor: '#333'
                  }
                }
              }}
              sx={datePickerStyle}
              format='DD/MM/YYYY'
              value={endDateFilter}
              onChange={(newValue: any) => setEndDateFilter(newValue)}
            />
          </LocalizationProvider>
        </Box>

        <Paper
          sx={{ width: '100%', overflow: 'hidden', backgroundColor: '#333' }}
        >
          <DataGrid
            rows={dataRows}
            columns={columns}
            paginationMode='server'
            rowCount={totalRows}
            pageSizeOptions={[5, 10, 25]}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={({ page, pageSize }) => {
              setPage(page);
              setPageSize(pageSize);
            }}
            disableRowSelectionOnClick
            disableColumnMenu
            sx={{
              color: 'white',
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#5243AAff',
                color: 'white'
              },
              '& .MuiDataGrid-cell': {
                borderBottom: '1px solid #444'
              },
              '& .MuiDataGrid-menuList': {
                backgroundColor: '#333'
              },
              '& .MuiList-root': {
                backgroundColor: '#333'
              },
              '& .MuiTablePagination-root': {
                color: 'white'
              },
              '& .MuiSvgIcon-root': {
                color: 'white'
              },
              '& .MuiPickersToolbar-root': {
                backgroundColor: '#333'
              },
              '& .MuiDateCalendar-root': {
                backgroundColor: '#333'
              }
            }}
          />
        </Paper>
      </Box>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity='error'
          sx={{ width: '100%' }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default QuizFeedbackSummary;
