import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Box, Typography, TextField, Button, Paper } from '@mui/material';
import NextQuestion from './NextQuestion';
import { postAnswer } from '../../../clients/rotr-client';
import { UUID } from 'crypto';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';
import NextButton from './Buttons/Next';
// Add this import
import {
  DndContext,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
  TouchSensor,
  MouseSensor,
  DragOverlay,
  DragEndEvent
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { set } from 'lodash';
import { text } from 'stream/consumers';
import { ResponseAnswer } from './gameTypes';
import RuleDisplay from './RuleDisplay';

interface Option {
  optionId: string;
  optionContent: string;
}

interface Question {
  attemptID: UUID;
  content: string;
  instruction: string;
  mediaURL: string;
  options: Option[];
  questionID: UUID;
  questionNumber: number;
  rules: string[];
  token: string;
  totalQuestions: number;
  type: string;
}

interface RenderDragNDropQuestionProps {
  attemptId: UUID;
  questionId: UUID;
  currentQuestion: Question;
  currentQuestionIndex: number;
  submitted: boolean;
  setSubmitted: (value: boolean) => void;
  handleNextQuestion: () => void;
  updateScore: (value: number) => void;
  setTimerRunning: (value: boolean) => void;
}

const Droppable: React.FC<{
  id: string;
  children: React.ReactNode;
  submitted: boolean;
  answerResults: { [key: string]: boolean };
  answerReceived: boolean; // Add this prop
}> = ({ id, children, submitted, answerResults, answerReceived }) => {
  const { isOver, setNodeRef } = useDroppable({
    id
  });
  const theme = useTheme();

  const style = {
    backgroundColor: isOver
      ? 'grey'
      : submitted && answerReceived // Only change color when answer is received
      ? answerResults[id]
        ? 'rgba(0, 255, 0, 0.2)'
        : 'rgba(255, 0, 0, 0.2)'
      : 'rgb(18, 21, 24)',
    padding: '8px',
    borderRadius: '4px',
    minWidth: '5em',
    minHeight: '1.25em',
    display: 'inline-block',
    margin: '0 4px',
    justifyContent: 'center',
    border:
      submitted && answerReceived // Only change border when answer is received
        ? answerResults[id]
          ? '2px solid green'
          : '2px solid red'
        : '1px solid black'
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  );
};

const Draggable: React.FC<{ id: string; children: React.ReactNode }> = ({
  id,
  children
}) => {
  const theme = useTheme();
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id
    });

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    transition: 'transform 0.2s ease',
    border: '1px solid whitesmoke',
    padding: '8px',
    borderRadius: '4px',
    margin: '0.5em',
    cursor: 'grab',
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.custom.page
  };

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </div>
  );
};
const DraggableDropped: React.FC<{
  id: string;
  children: React.ReactNode;
  onRemove: () => void;
  submitted: boolean; // Add this prop
}> = ({ id, children, onRemove, submitted }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id
    });

  const style: React.CSSProperties = {
    textAlign: 'center',
    width: '100%',
    lineHeight: '1',
    position: 'relative'
  };

  const closeButtonStyle: React.CSSProperties = {
    position: 'absolute',
    top: '-20px',
    right: '-20px',
    cursor: 'pointer',
    background: 'red',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    display: submitted ? 'none' : 'flex' // Hide when submitted
  };

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {!submitted && ( // Only render the close button if not submitted
        <button
          style={closeButtonStyle}
          onClick={onRemove}
          onTouchStart={onRemove}
        >
          &times;
        </button>
      )}
      {children}
    </div>
  );
};
const RenderDragNDropQuestion: React.FC<RenderDragNDropQuestionProps> = ({
  attemptId,
  questionId,
  currentQuestion,
  currentQuestionIndex,
  submitted,
  setSubmitted,
  handleNextQuestion,
  updateScore,
  setTimerRunning
}) => {
  const initialOptions = currentQuestion?.options || [];

  const [optionIds, setOptionIds] = useState<string[]>([]);
  const [droppedOptions, setDroppedOptions] = useState<(Option | null)[]>(
    Array(initialOptions.length).fill(null)
  );
  const [availableOptions, setAvailableOptions] = useState<Option[]>([]);
  const [showNextButton, setShowNextButton] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [dropBoxIndices, setDropBoxIndices] = useState<{
    [key: string]: number;
  }>({});
  const [answerResults, setAnswerResults] = useState<{
    [key: string]: boolean;
  }>({});
  const [correctAnswers, setCorrectAnswers] = useState<{
    [key: string]: string;
  }>({});
  const [openRuleModal, setOpenRuleModal] = useState(false);
  const [selectedRuleId, setSelectedRuleId] = useState<string | undefined>(
    undefined
  );
  const [answerReceived, setAnswerReceived] = useState(false);

  const extractDropBoxes = (content: string) => {
    const parts = content.split(/({[^}]*})/g);
    const dropBoxes = parts
      .filter((part) => part.startsWith('{') && part.endsWith('}'))
      .map((part) => part.slice(1, -1));
    console.log('Drop boxes:', dropBoxes);
    const indices: { [key: string]: number } = {};
    dropBoxes.forEach((box, index) => {
      indices[box] = index;
    });
    setDropBoxIndices(indices);
  };
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 50, tolerance: 5 }
    })
  );

  const handleRemoveOption = (index: number) => {
    const option = droppedOptions[index];
    if (option) {
      setDroppedOptions(
        droppedOptions.map((opt, i) => (i === index ? null : opt))
      );
      setAvailableOptions([...availableOptions, option]);
    }
  };
  useEffect(() => {
    setTimerRunning(false);
    if (initialOptions.length > 0) {
      setDroppedOptions(Array(initialOptions.length).fill(null));
      setAvailableOptions(initialOptions);
      // extractDropBoxes(currentQuestion.content);
    }
    setTimerRunning(true);
    setShowNextButton(false);
  }, [initialOptions]);

  useEffect(() => {
    extractDropBoxes(currentQuestion.content);
  }, [currentQuestion.content]);
  useEffect(() => {
    console.log(
      'Dropped options:',
      droppedOptions.map((option) => (option ? option.optionContent : 'null'))
    );
    console.log(
      'Available options:',
      availableOptions.map((option) => option.optionContent)
    );
  }, [droppedOptions, availableOptions]);

  const handleSubmit = async () => {
    if (submitted) return;
    setSubmitted(true);
    setAnswerReceived(false); // Reset answerReceived state

    const answers = initialOptions.map((option) => {
      const droppedIndex = droppedOptions.findIndex(
        (dropped) => dropped && dropped.optionId === option.optionId
      );
      return {
        id: option.optionId,
        drop_zone_id:
          droppedIndex !== -1
            ? Object.keys(dropBoxIndices).find(
                (key) => dropBoxIndices[key] === droppedIndex
              ) || null
            : null
      };
    });

    try {
      setTimerRunning(false);
      const APIResponse = await postAnswer(attemptId, questionId, answers);
      setIsChecked(true);

      // Process the response and update states
      const newCorrectAnswers: { [key: string]: string } = {};
      APIResponse.responseAnswers.forEach((answer: ResponseAnswer) => {
        newCorrectAnswers[answer.correct_drop_zone] = answer.id;
      });
      setCorrectAnswers(newCorrectAnswers);

      // Compare user answers with correct answers
      const newAnswerResults: { [key: string]: boolean } = {};
      Object.keys(dropBoxIndices).forEach((dropZoneId) => {
        const userAnswer = droppedOptions[dropBoxIndices[dropZoneId]];
        newAnswerResults[dropZoneId] =
          userAnswer?.optionId === newCorrectAnswers[dropZoneId];
      });
      setAnswerResults(newAnswerResults);

      // Check if all answers are correct
      const allCorrect = Object.values(newAnswerResults).every(
        (result) => result === true
      );
      setIsCorrect(allCorrect);

      setAnswerReceived(true); // Set answerReceived to true after processing
      setShowNextButton(true);
      updateScore(APIResponse.score);
    } catch (error) {
      console.error('Error submitting answer:', error);
      setSubmitted(false);
      setAnswerReceived(false);
    }
  };

  const renderCorrectAnswers = () => {
    if (!submitted || !correctAnswers) return null;

    const parts = currentQuestion.content.split(/({[^}]*})/g);
    const contentParts = parts.map((part, index) => {
      if (part.startsWith('{') && part.endsWith('}')) {
        const optionId = part.slice(1, -1);
        const correctOptionId = correctAnswers[optionId];
        const correctOption = initialOptions.find(
          (opt) => opt.optionId === correctOptionId
        );
        return (
          <span key={index} style={{ textDecoration: 'underline' }}>
            {correctOption ? correctOption.optionContent : part}
          </span>
        );
      }
      return <span key={index}>{part}</span>;
    });

    return (
      <Paper
        elevation={3}
        sx={{
          p: 2,
          mt: 3,
          backgroundColor: 'custom.page',
          color: 'whitesmoke'
        }}
      >
        <Typography variant='h6' gutterBottom>
          Correct Answer:
        </Typography>
        <Typography>{contentParts}</Typography>
      </Paper>
    );
  };

  const processContent = (content: string) => {
    const parts = content.split(/({[^}]*})/g);
    const contentParts = parts.map((part, index) => {
      if (part.startsWith('{') && part.endsWith('}')) {
        const optionId = part.slice(1, -1);
        const userAnswer = droppedOptions[dropBoxIndices[optionId]];
        const isCorrect = answerResults[optionId];

        return (
          <Droppable
            key={index}
            id={optionId}
            submitted={submitted}
            answerResults={answerResults}
            answerReceived={answerReceived} // Pass the new prop
          >
            {userAnswer && (
              <Box display='flex' flexDirection='row' alignItems='center'>
                <DraggableDropped
                  id={userAnswer.optionId}
                  onRemove={() => handleRemoveOption(dropBoxIndices[optionId])}
                  submitted={submitted}
                >
                  {userAnswer.optionContent}
                </DraggableDropped>
                {submitted &&
                  answerReceived && ( // Only show icons when answer is received
                    <Box
                      sx={{
                        fontSize: '0.9rem',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '4px'
                      }}
                    >
                      {isCorrect ? (
                        <CheckCircleIcon
                          fontSize='small'
                          sx={{ color: 'green' }}
                        />
                      ) : (
                        <ErrorOutlineIcon
                          fontSize='small'
                          sx={{ color: 'red' }}
                        />
                      )}
                    </Box>
                  )}
              </Box>
            )}
          </Droppable>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });

    return { contentParts };
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { over, active } = event;

    if (over) {
      const option = availableOptions.find((opt) => opt.optionId === active.id);

      if (option) {
        const index = dropBoxIndices[over.id];

        // Check if the dropbox is already occupied
        if (droppedOptions[index] === null) {
          const newDroppedOptions = [...droppedOptions];
          newDroppedOptions[index] = option;
          setDroppedOptions(newDroppedOptions);

          // Remove the option from available options
          setAvailableOptions(
            availableOptions.filter((opt) => opt.optionId !== option.optionId)
          );
        } else {
          // Optionally, you can add some feedback here (e.g., a toast notification)
          console.log('This dropbox is already occupied');
        }
      }
    }
  };

  const handleRuleClick = (ruleId: string) => {
    setSelectedRuleId(ruleId);
    setOpenRuleModal(true);
  };

  const { contentParts } = processContent(currentQuestion.content || '');

  return (
    <DndContext sensors={sensors} onDragEnd={onDragEnd}>
      <Box
        sx={{
          width: '100%',
          margin: '16px 0', // Increased margin
          textAlign: 'left',
          color: 'white',
          minHeight: '200px'
        }}
      >
        <Box
          sx={{
            backgroundColor: isChecked
              ? isCorrect
                ? 'green'
                : 'custom.page'
              : 'purple',
            padding: '24px', // Increased padding
            borderRadius: '12px', // Slightly increased border radius
            marginBottom: '20px' // Increased margin bottom
          }}
        >
          <Typography
            variant='h6'
            sx={{ fontSize: '1.1rem', marginBottom: '12px' }}
          >
            <strong>{currentQuestion.instruction}</strong>
          </Typography>
          <RuleDisplay
            inputRules={currentQuestion.rules}
            showRuleLink={false}
          />
          {isChecked && isCorrect && (
            <Typography
              variant='h4'
              sx={{ color: 'white', marginTop: '16px', fontWeight: 'bold' }}
            >
              Correct! <CheckCircleTwoToneIcon />
            </Typography>
          )}
        </Box>
        <Box
          className='content'
          sx={{
            marginTop: '16px',
            marginBottom: '24px', // Added margin bottom
            color: 'white',
            lineHeight: '2.5',
            fontSize: '1.1rem' // Slightly increased font size
          }}
        >
          {contentParts}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginTop: '24px', // Added margin top
            gap: '12px' // Added gap between draggable items
          }}
        >
          {availableOptions.map((option) => (
            <Draggable key={option.optionId} id={option.optionId}>
              {option.optionContent}
            </Draggable>
          ))}
        </Box>

        <Box
          display='flex'
          justifyContent='center'
          marginTop='32px' // Increased margin top
          marginBottom='32px' // Increased margin bottom
        >
          {showNextButton && <NextQuestion onClick={handleNextQuestion} />}

          {!submitted && (
            <Button
              variant='contained'
              sx={{
                backgroundColor: 'purple',
                '&:hover': {
                  backgroundColor: '#800080'
                },
                width: {
                  xs: '100%',
                  sm: '50%',
                  md: '120px', // Increased button width
                  lg: '120px',
                  xl: '120px'
                },
                padding: '12px 24px', // Added padding to the button
                fontSize: '1rem' // Increased font size
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )}
        </Box>
        {renderCorrectAnswers()}
      </Box>
    </DndContext>
  );
};

export default RenderDragNDropQuestion;
