import React, { useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ContactFormState {
  email: string;
  message: string;
}

const GameplayFAQ: React.FC = () => {
  return (
    <div className='main-content'>
      <Box p={8} display='flex' flexDirection='column' alignItems='center'>
        <Typography variant='h4' gutterBottom>
          Help & FAQ
        </Typography>
        <Typography variant='h6' gutterBottom>
          Frequently Asked Questions
        </Typography>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>
              {' '}
              What can I expect when I start playing Shakedown?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              Shakedown covers the entire IRPCS through a mix of engaging
              activities, including 23 Training Sessions, 8 Section Challenges,
              and 1 comprehensive Final Challenge.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>
              Which parts of the rules can I access right from the start?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              You’ll kick off with four initial sections available from day one:
              <ul>
                <li>Lights & Shapes - Basic (R20-31)</li>
                <li>Any Condition of Visibility (R4-10)</li>
                <li>Restricted Visibility (R19)</li>
                <li>Sound & Light Signals (R32-37)</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>
              When will more sections of the game become available?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              As you progress, we’ll unlock additional sections for you,
              including more advanced levels of Lights & Shapes and other key
              areas like General Rules and Distress Signals.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>How should I tackle the Training Sessions?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              Feel free to explore the Training Sessions in any order you
              prefer. They’re designed to be retaken as needed, allowing you to
              solidify your understanding, although the questions will stay the
              same each time.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>
              What happens once I finish all Training Sessions for a section?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              After you complete all Training Sessions within a section, you'll
              unlock the Section Challenge. This includes a blend of review and
              new questions to test your mastery of the material.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>What’s involved in the Final Challenge?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              The Final Challenge is a mix of questions from all across the
              course. It’s your chance to make sure you have a good
              understanding of the rules and are ready for your next Rules of
              the Road Assessment.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>Can other players see my scores?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              Yes, your scores on the Section and Final Challenge will appear on
              a leaderboard shared with your cohort, so you can see how you
              stack up against others.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>
              What do abbreviations like NUC, RAM, CBD, SV, PDV & VEIF mean?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              These abbreviations are commonly used in maritime navigation and
              refer to specific vessel types or conditions:
              <ul>
                <li>
                  <strong>NUC:</strong> Not Under Command
                </li>
                <li>
                  <strong>RAM:</strong> Restricted in Ability to Manoeuvre
                </li>
                <li>
                  <strong>CBD:</strong> Constrained By Draught
                </li>
                <li>
                  <strong>SV:</strong> Sailing Vessel
                </li>
                <li>
                  <strong>PDV:</strong> Power-Driven Vessel
                </li>
                <li>
                  <strong>VEIF:</strong> Vessel Engaged in Fishing
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>How much should I aim to complete each day?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              You should be completing a minimum 1-2 activities every working
              day during the 4-week trial. This pacing ensures you’re
              well-prepared for any Rules of the Road test you might have coming
              up.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
};

export default GameplayFAQ;
