import React from 'react';
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface NextQuestionProps {
  onClick: () => void;
}

const NextQuestion: React.FC<NextQuestionProps> = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant='contained'
      endIcon={<ArrowForwardIcon />}
      sx={{
        backgroundColor: 'purple',
        '&:hover': {
          backgroundColor: '#800080'
        },
        color: 'white',
        padding: '10px 20px',
        fontSize: '1rem',
        textTransform: 'none',
        width: '150px'
      }}
    >
      NEXT
    </Button>
  );
};

export default NextQuestion;
