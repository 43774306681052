import React, { useEffect, useState } from 'react';
import { Typography, Box, IconButton, Modal, Grid } from '@mui/material';
import { Snackbar, Alert } from '@mui/material';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { Timestamp } from 'aws-sdk/clients/signer';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import StarIcon from '@mui/icons-material/Star';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BookIcon from '@mui/icons-material/Book';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { LineChart } from '@mui/x-charts/LineChart';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';

//Own
import { getReciteFeedbackSummary } from '../../clients/rotr-client';
import { set } from 'lodash';

interface Props {
  ruleString: string;
  ruleId: string;
  fullTitle: string;
  difficulty: string;
  difficultyLabel: string;
  triggerOpen: boolean;
  onClose: () => void;
}

const RuleModal: React.FC<Props> = ({
  ruleString,
  ruleId,
  fullTitle,
  difficulty,
  difficultyLabel,
  triggerOpen,
  onClose
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [xAxisData, setXAxisData] = React.useState<Date[]>([]);
  const [seriesData, setSeriesData] = React.useState<number[]>([]);
  const [allTimeHighScore, setAllTimeHighScore] = React.useState<number | null>(
    null
  );
  const [ruleCoverage, setRuleCoverage] = React.useState<number | null>(null);
  const [latestAttempt, setLatestAttempt] = React.useState<Timestamp | null>(
    null
  );
  const [accuracyOverTime, setAccuracyOvertime] = React.useState<
    {
      timestamp: Timestamp;
      accuracy: number;
    }[]
  >([]);
  const [errMsg, setErrMsg] = React.useState<string>('');
  const [alertSeverity, setAlertSeverity] = React.useState<
    'error' | 'success' | 'info' | 'warning' | undefined
  >('error');

  const [openError, setOpenError] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [dataWarning, setDataWarning] = React.useState<boolean>(false);
  const handleCloseError = () => setOpenError(false);
  const fetchData = async () => {
    try {
      setIsLoading(true);

      // Fetch new data
      const response = await getReciteFeedbackSummary(
        ruleId,
        Number(difficulty)
      );
      // Check if there is enough data to display
      if (!response.accuracyOverTime || response.accuracyOverTime.length <= 1) {
        setDataWarning(true);
        setIsLoading(false);
        return;
      }
      response.highestAccuracy
        ? setAllTimeHighScore(response.highestAccuracy)
        : setAllTimeHighScore(null);
      response.percentageOfRuleAttempted
        ? setRuleCoverage(response.percentageOfRuleAttempted)
        : setRuleCoverage(null);
      response.latestAttempt
        ? setLatestAttempt(response.latestAttempt)
        : setLatestAttempt(null);
      response.accuracyOverTime
        ? setAccuracyOvertime(response.accuracyOverTime)
        : setAccuracyOvertime([]);
      setXAxisData(
        response.accuracyOverTime.map(
          (record: { timestamp: Timestamp; accuracy: number }) =>
            new Date(record.timestamp)
        )
      );
      setSeriesData(
        response.accuracyOverTime.map(
          (record: { timestamp: Timestamp; accuracy: number }) =>
            record.accuracy
        )
      );
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching recite feedback summary:', error);
      setErrMsg('Error fetching recite feedback summary');
      setAlertSeverity('error');
      setOpenError(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setDataWarning(false);
    onClose();
  };

  useEffect(() => {
    if (triggerOpen) {
      // Clear previous data
      setAllTimeHighScore(null);
      setRuleCoverage(null);
      setLatestAttempt(null);
      setAccuracyOvertime([]);
      setXAxisData([]);
      setSeriesData([]);
      setOpen(true);
      setDataWarning(false);
      fetchData();
    } // Only load new data when the modal is being opened.
  }, [triggerOpen]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '600px',
          maxHeight: '90vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '4px',
          p: 6
        }}
      >
        <IconButton
          size='large'
          sx={{
            color: 'black',
            position: 'absolute',
            top: -8,
            left: -8
          }}
          onClick={handleClose}
        >
          <CancelTwoToneIcon fontSize='large' />
        </IconButton>
        {dataWarning ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              padding: 3
            }}
          >
            {' '}
            {isLoading ? (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                height='100%'
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <ErrorOutlineIcon color='secondary' fontSize='large' />
                <Typography variant='h6' align='center' color='black'>
                  There hasn't been enough activity to calculate your progress.
                </Typography>
              </>
            )}
          </Box>
        ) : (
          <Box sx={{ p: 1 }}>
            {isLoading ? (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                height='100%'
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Grid sx={{ ml: 1 }} container spacing={1} alignItems='center'>
                  <Grid textAlign={'center'} item xs={12}>
                    <Typography variant='h6' color='black'>
                      {fullTitle}
                      <Chip
                        variant='outlined'
                        sx={{ ml: 2, color: 'black' }}
                        label={difficultyLabel}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <LineChart
                  xAxis={[
                    {
                      scaleType: 'utc',
                      data: xAxisData,

                      tickLabelStyle: {
                        fill: 'black'
                      }
                    }
                  ]}
                  series={[
                    {
                      data: seriesData
                    }
                  ]}
                  width={500}
                  height={300}
                  yAxis={[
                    {
                      label: 'Percentage Accuracy %',
                      labelStyle: {
                        fill: 'black'
                      },
                      tickLabelStyle: {
                        fill: 'black'
                      }
                    }
                  ]}
                  sx={{ color: 'black' }}
                />
                <Stack spacing={2} direction='column'>
                  <Tooltip title='This is your best score so far. Keep practicing to improve it!'>
                    <Chip
                      avatar={
                        <Avatar>
                          <StarIcon color='primary' />
                        </Avatar>
                      }
                      label={`Top Accuracy Result: ${allTimeHighScore}%`}
                      sx={{ justifyContent: 'flex-start', color: 'black' }}
                    />
                  </Tooltip>
                  <Tooltip title='This is the date and time of your latest attempt. Keep practicing regularly!'>
                    <Chip
                      avatar={
                        <Avatar>
                          <AccessTimeIcon color='secondary' />
                        </Avatar>
                      }
                      label={`Latest Attempt: ${
                        latestAttempt
                          ? new Date(latestAttempt).toLocaleString()
                          : 'N/A'
                      }`}
                      sx={{ justifyContent: 'flex-start', color: 'black' }}
                    />
                  </Tooltip>
                  <Tooltip title='This is the percentage of the rule that you have tried to recite. Try to reach 100%!'>
                    <Chip
                      avatar={
                        <Avatar>
                          <BookIcon color='action' />
                        </Avatar>
                      }
                      label={`Rule Completion: ${ruleCoverage}%`}
                      sx={{ justifyContent: 'flex-start', color: 'black' }}
                    />
                  </Tooltip>
                </Stack>
              </>
            )}
          </Box>
        )}
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleCloseError}
        >
          <Alert
            onClose={handleCloseError}
            severity={alertSeverity}
            sx={{ width: '100%' }}
          >
            {errMsg}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default RuleModal;
