import React from 'react';
import { useParams } from 'react-router-dom';
import ScoreBoard from './ScoreBoard';
import { UUID } from 'crypto';
import { Paper, Box } from '@mui/material';
import NavCompact from '../../header/NavCompact';

const ScoreBoardDisplay: React.FC = () => {
  const { stepId } = useParams<{ stepId: UUID }>();

  return (
    <Paper
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: 'rgb(22, 26, 29)',
        padding: '0',
        overflow: 'auto',
        height: '100vh',
        borderRadius: '0'
      }}
    >
      <div
        style={{
          width: '100%',
          maxWidth: '800px',
          padding: '0 24px',
          color: 'whitesmoke',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100vh',
          minHeight: '400px',
          backgroundColor: '#1d2125'
        }}
      >
        <Box
          className='gameHeader'
          flexGrow={0}
          flexShrink={0}
          style={{
            padding: '6px 12px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            zIndex: 1
          }}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start', // Changed to align left
              alignItems: 'center',
              padding: '0'
            }}
          >
            <NavCompact />
          </Box>
          <Box
            style={{
              width: '100%',
              maxWidth: '800px',
              padding: '24px',
              color: 'whitesmoke',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#1d2125'
            }}
          >
            <Box
              style={{
                marginTop: '2rem',
                padding: '2rem',
                backgroundColor: '#161A1Dff',
                color: 'whitesmoke',
                width: '100%',
                maxWidth: '600px',
                minWidth: '200px'
              }}
            >
              <ScoreBoard stepId={stepId} />
            </Box>
          </Box>
        </Box>
      </div>
    </Paper>
  );
};

export default ScoreBoardDisplay;
