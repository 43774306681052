import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import FlagCircleTwoToneIcon from '@mui/icons-material/FlagCircleTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import { keyframes } from '@mui/system';
import Badge from '@mui/material/Badge';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StarExplanationModal from './StarExplainationModal';
import Button from '@mui/material/Button';

interface GameSectionBodyProps {
  steps: Array<Step>;
  starCount: number;
}

interface CompletionStatus {
  completed: boolean;
  completed_at: string;
}

interface Step {
  id: string;
  name: string;
  completionStatus: CompletionStatus;
}

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const renderStars = (starCount: number) => {
  const shadowStyle = {
    filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.6))'
  };
  const colors = {
    1: '#E09663',
    2: '#D9D9D9',
    3: '#FCE56F'
  };

  switch (starCount) {
    case 0:
      return [
        <StarBorderIcon key='star1' />,
        <StarBorderIcon key='star2' />,
        <StarBorderIcon key='star3' />
      ];
    case 1:
      return [
        <StarIcon key='star1' style={{ ...shadowStyle, color: colors[1] }} />,
        <StarBorderIcon key='star2' />,
        <StarBorderIcon key='star3' />
      ];
    case 2:
      return [
        <StarIcon key='star1' style={{ ...shadowStyle, color: colors[1] }} />,
        <StarIcon key='star2' style={{ ...shadowStyle, color: colors[2] }} />,
        <StarBorderIcon key='star3' />
      ];
    case 3:
      return [
        <StarIcon key='star1' style={{ ...shadowStyle, color: colors[1] }} />,
        <StarIcon key='star2' style={{ ...shadowStyle, color: colors[2] }} />,
        <StarIcon key='star3' style={{ ...shadowStyle, color: colors[3] }} />
      ];
    default:
      return [
        <StarBorderIcon key='star1' />,
        <StarBorderIcon key='star2' />,
        <StarBorderIcon key='star3' />
      ];
  }
};

const renderSteps = (
  steps: Array<Step>,
  starCount: number,
  isModalOpen: boolean,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  handleCloseModal: () => void
) => {
  return steps.map((step, i) => {
    const isFinalStep = i === steps.length - 1;
    const allPreviousCompleted = steps
      .slice(0, i)
      .every((prevStep) => prevStep.completionStatus.completed);

    const StepContent = () => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '30px',
          padding: '5px 15px 5px 5px',
          maxWidth: '250px',
          width: '100%',
          transition: 'all 0.3s ease',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            transform: 'translateY(-5px)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
          }
        }}
      >
        <IconButton sx={{ p: 0 }}>
          {isFinalStep ? (
            allPreviousCompleted ? (
              <EmojiEventsTwoToneIcon
                sx={{
                  fontSize: 40,
                  color: step.completionStatus.completed ? '#1bc8f8bc' : 'grey',
                  animation: `${pulse} 2s infinite ease-in-out`
                }}
              />
            ) : (
              <LockTwoToneIcon
                sx={{
                  fontSize: 40,
                  color: 'grey',
                  margin: '8px'
                }}
              />
            )
          ) : (
            <FlagCircleTwoToneIcon
              sx={{
                fontSize: 40,
                color: step.completionStatus.completed ? '#1bc8f8bc' : 'grey'
              }}
            />
          )}
        </IconButton>
        <Typography
          variant='body2'
          sx={{
            color: 'white',
            marginLeft: '10px',
            fontWeight: 'bold',
            flexGrow: 1,
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {step.name}
        </Typography>
      </Box>
    );

    const stepContent = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          paddingTop: '20px',
          transform: isFinalStep
            ? 'none'
            : `translateX(${i % 2 === 0 ? '-10%' : '10%'})`,
          '@media (max-width: 600px)': {
            transform: 'none'
          }
        }}
      >
        {isFinalStep && !allPreviousCompleted ? (
          <Tooltip
            title='Unlock by completing all previous training sessions'
            placement='top'
            arrow
          >
            <Box sx={{ width: '100%', maxWidth: '250px' }}>
              <StepContent />
            </Box>
          </Tooltip>
        ) : (
          <Link
            to={`/games/shake-down/steps/${step.id}/intro`}
            style={{ textDecoration: 'none', width: '100%', maxWidth: '250px' }}
          >
            <StepContent />
          </Link>
        )}
      </Box>
    );

    return <React.Fragment key={i}>{stepContent}</React.Fragment>;
  });
};

const GameSectionBody: React.FC<GameSectionBodyProps> = ({
  steps,
  starCount
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const finalStep = steps[steps.length - 1];
  const allPreviousCompleted = steps
    .slice(0, -1)
    .every((step) => step.completionStatus.completed);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto',
        padding: '0 20px',
        boxSizing: 'border-box'
      }}
    >
      {renderSteps(
        steps,
        starCount,
        isModalOpen,
        setIsModalOpen,
        handleCloseModal
      )}
      {allPreviousCompleted && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '20px'
            }}
          >
            <StarExplanationModal
              open={isModalOpen}
              onClose={handleCloseModal}
            />
            {renderStars(starCount)}
            <Tooltip title='How do star achievements work?' arrow>
              <InfoOutlinedIcon
                onClick={() => setIsModalOpen(true)}
                sx={{
                  color: 'whitesmoke',
                  marginLeft: '10px',
                  cursor: 'pointer'
                }}
              />
            </Tooltip>
          </Box>
          <Button
            variant='contained'
            color='primary'
            startIcon={<LeaderboardIcon />}
            component={Link}
            to={`/games/shake-down/scoreboard/${finalStep.id}`}
            sx={{
              marginTop: '20px',
              textDecoration: 'none',
              backgroundColor: '#1bc8f8bc',
              '&:hover': {
                backgroundColor: '#147c9e'
              }
            }}
          >
            Scoreboard
          </Button>
        </>
      )}
    </Box>
  );
};

export default GameSectionBody;
