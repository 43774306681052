import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Alert,
  Chip,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getQuizRankings } from '../../../clients/rotr-client';

interface Ranking {
  step_name: string;
  section_name: string;
  max_score: number;
  score: number;
  time_taken_secs: number;
  rank: number;
}

const StyledCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(2),
  boxShadow: theme.shadows[3]
}));

const QuizRankings: React.FC = () => {
  const [rankings, setRankings] = useState<Ranking[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        const response = await getQuizRankings();
        if (!response.ok) {
          throw new Error('Failed to fetch rankings');
        }
        const data = await response.json();
        setRankings(data);
        setIsLoading(false);
      } catch (err) {
        setError('Error fetching rankings. Please try again later.');
        setIsLoading(false);
      }
    };

    fetchRankings();
  }, []);

  if (isLoading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='200px'
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity='error'>{error}</Alert>;
  }

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 2 }}>
      <Typography variant='h4' gutterBottom>
        Your Quiz Rankings
      </Typography>
      <Grid container spacing={3}>
        {rankings.map((ranking, index) => (
          <Grid item xs={12} key={index}>
            <StyledCard>
              <CardContent>
                <Typography variant='h6' gutterBottom>
                  {ranking.step_name} - {ranking.section_name}
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant='body2' color='text.secondary'>
                      Rank
                    </Typography>
                    <Chip
                      label={`#${ranking.rank}`}
                      color='primary'
                      sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant='body2' color='text.secondary'>
                      Score
                    </Typography>
                    <Typography variant='body1'>
                      {ranking.score} / {ranking.max_score}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant='body2' color='text.secondary'>
                      Time Taken
                    </Typography>
                    <Typography variant='body1'>
                      {Math.floor(ranking.time_taken_secs / 60)}m{' '}
                      {ranking.time_taken_secs % 60}s
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default QuizRankings;
